import axios from '@axios'

export default function paymentServices() {
  const startPayment = (queryParams) => {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    // eslint-disable-next-line radix
    const v = parseInt(Math.random() * 1000000)

    if (process.env.VUE_APP_GN_SANBOX === 'true') {
      s.src = `https://sandbox.gerencianet.com.br/v1/cdn/${process.env.VUE_APP_GN_IDENTITY}/${v}`
    } else {
      s.src = `https://api.gerencianet.com.br/v1/cdn/${process.env.VUE_APP_GN_IDENTITY}/${v}`
    }

    s.async = false
    s.id = `${process.env.VUE_APP_GN_IDENTITY}`
    if (!document.getElementById(`${process.env.VUE_APP_GN_IDENTITY}`)) {
      document.getElementsByTagName('head')[0].appendChild(s)
    }
    window.$gn = {
      validForm: true,
      processed: false,
      done: {},
      ready(fn) {
        window.$gn.done = fn
      },
    }
  }

  const getPaymentToken = (queryParams) => {
    const valid = queryParams.valid.split('/')
    const expiration_month = valid[0]
    const expiration_year = `20${valid[1]}`
    console.log(`${expiration_month}/${expiration_year}`)
    return new Promise((resolve, reject) => {
      window.$gn.ready((checkout) => {
        checkout.getPaymentToken(
          {
            brand: queryParams.brand, // bandeira do cartão
            number: queryParams.number, // número do cartão
            cvv: queryParams.cvv, // código de segurança
            expiration_month, // mês de vencimento
            expiration_year, // ano de vencimento
          },
          (error, response) => {
            if (error) {
              console.log(error)
              reject(error)
              return error
            }
            // Trata a respostae
            resolve(response)
            return response
          }
        )
      })
    })
  }
  const getInstallments = async (queryParams) =>
    new Promise((resolve, reject) => {
      console.log('começou installments')
      window.$gn.ready((checkout) => {
        console.log('rodou ready')
        checkout.getInstallments(
          queryParams.totalValue * 100, // valor total da cobrança
          queryParams.brand, // bandeira do cartão
          (error, response) => {
            if (error) {
              // Trata o erro ocorrido
              console.log(error)
              reject(error)
              return error
            }
            // Trata a respostae
            resolve(response)
            return response
          }
        )
      })
    })

  const getBoleto = async (queryParams) => {
    const res = await axios.post('/boleto-payment', queryParams)
    console.log(res)
    return res
  }

  const finishPayment = async (queryParams) => {
    console.log(queryParams)
    const res = await axios.post('/card-payment', queryParams)
    console.log(res)
    return res
  }

  const getOrderGn = async (queryParams) => {
    const res = await axios.post('/order/paid/gn', queryParams)
    console.log(res)
    return res
  }

  const saveAddress = async (queryParams) => {
    const res = await axios.post('/save-address', queryParams)
    console.log(res)
    return res
  }

  return {
    startPayment,
    getPaymentToken,
    getInstallments,
    getBoleto,
    finishPayment,
    getOrderGn,
    saveAddress,
  }
}
