<template>
  <b-card>
    <form-wizard
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      class="checkout-form-wizard"
      :hide-buttons="false"
    >
      <!-- account detail tab -->
      <tab-content
        title="Carrinho"
        icon="feather icon-shopping-cart"
      >
        <cart-items-step @next-step="formWizardNextStep" />
      </tab-content>

      <!-- personal data -->
      <tab-content
        title="Dados pessoais"
        icon="feather icon-user"
      >
        <customer-data-step
          @next-step="formWizardNextStep"
          @previous-step="formWizardPreviousStep"
        />
      </tab-content>

      <!-- address -->
      <tab-content
        title="Endereço"
        icon="feather icon-map-pin"
      >
        <address-step
          @next-step="formWizardNextStep"
          @previous-step="formWizardPreviousStep"
        />
      </tab-content>

      <!-- Forma de pagamento -->
      <tab-content
        title="Forma de pagamento"
        icon="feather icon-credit-card"
      >
        <payment-step
          @previous-step="formWizardPreviousStep"
        />
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/assets/fonts/feather/iconfont.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import CartItemsStep from './CartItemsStep.vue'
import CustomerDataStep from './CustomerDataStep.vue'
import AddressStep from './AddressStep.vue'
import PaymentStep from './PaymentStep.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // steps
    CartItemsStep,
    CustomerDataStep,
    AddressStep,
    PaymentStep
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    const formWizardPreviousStep = () => {
      refFormWizard.value.prevTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPreviousStep,
    }
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style>
.wizard-navigation {
  z-index: 0 !important;
  position:relative;
}

div.card-footer {
  display: none !important;
  color: #000 !important;
}
</style>
