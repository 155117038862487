var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"list-view product-checkout",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Dados pessoais ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Informe seus dados")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Seu nome","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"cpf","rules":"required|integer|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('b-form-input',{attrs:{"id":"cpf","placeholder":"111.222.333-44","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.cpf),callback:function ($$v) {_vm.$set(_vm.customer, "cpf", $$v)},expression:"customer.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"email@email.com","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"phone_number","vid":"phone_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Celular","label-for":"phone_number"}},[_c('vue-tel-input',{staticStyle:{"height":"37px","border-radius":"6px"},style:(errors.length > 0 ? 'border-color: red;' : ''),attrs:{"id":"phone_number","name":"phone_number","default-country":"BR","mode":"international"},model:{value:(_vm.customer.phone_number),callback:function ($$v) {_vm.$set(_vm.customer, "phone_number", $$v)},expression:"customer.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"birth","label":"Data de nascimento"}},[_c('flat-pickr',{staticClass:"form-control",style:(errors.length > 0 ? 'border-color: red;' : ''),attrs:{"id":"birth","config":{ dateFormat: 'd/m/Y'},"placeholder":"DD/MM/YYYY"},model:{value:(_vm.customer.birth),callback:function ($$v) {_vm.$set(_vm.customer, "birth", $$v)},expression:"customer.birth"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('previous-step')}}},[_vm._v(" Voltar ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Continuar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }